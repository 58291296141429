<template>
  <section class="table-items" v-loading="loading">
    <section class="input-filters d-flex align-items-center mt-4">
      <FilterBetweenDatesSelect
        :defaultMonthValue="true"
        @onAsyncDataForDates="onAsyncDataForDates"
      />
    </section>
    <section class="table-item-tax mt-5 border-radius bg-color-write">
      <b-row class="table-items-header">
        <b-col xs="12" sm="12" md="4">
          <h6 class="h6 ml-2">Impuesto</h6>
        </b-col>
        <b-col xs="12" sm="12" md="2">
          <h6 class="h6">Base imponible</h6>
        </b-col>
        <b-col xs="6" sm="12" md="2">
          <h6 class="h6">Notas créditos</h6>
        </b-col>
        <b-col xs="6" sm="12" md="2">
          <h6 class="h6">Total</h6>
        </b-col>
        <b-col xs="4" sm="12" md="2">
          <h6 class="h6">Impuesto total</h6>
        </b-col>
      </b-row>

      <div class="card-body table-items-content">
        <b-row class="">
          <b-col xs="12" sm="12" md="4" class="table-col-item">
            <span class="item-col-code font-semi-bold">IVA 12%</span>
          </b-col>
          <b-col xs="12" sm="12" md="2" class="table-col-item">
            <span class="item-col-code font-semi-bold">
              <FormatAmount :amount="sales.total_sales" />
            </span>
          </b-col>
          <b-col xs="12" sm="12" md="2" class="table-col-item">
            <span class="item-col-code font-semi-bold">
              <FormatAmount :amount="sales.total_credit_notes" />
            </span>
          </b-col>
          <b-col xs="12" sm="12" md="2" class="table-col-item">
            <span class="item-col-code font-semi-bold">
              <FormatAmount :amount="sales.total_net_sales" />
            </span>
          </b-col>
          <b-col xs="12" sm="12" md="2" class="table-col-item">
            <span class="item-col-code font-semi-bold">
              <FormatAmount :amount="sales.total_tax" />
            </span>
          </b-col>
        </b-row>
      </div>
    </section>
  </section>
</template>

<script>
import reportService from "../services/reportService";
import FilterBetweenDatesSelect from "../../components/FilterBetweenDatesSelect";
import FormatAmount from "@/components/General/FormatAmount";

export default {
  name: "SalesIvaList",
  data() {
    return {
      sales: [],
      loading: false
    };
  },
  computed: {},
  methods: {
    onAsyncDataForDates({ searchDates }) {
      this.searchDates = searchDates;
      this.sales = [];
      this.getSalesProducts();
    },
    async getSalesProducts() {
      try {
        this.loading = true;

        const response = await reportService
          .getSalesProducts({
            params: {
              ...{},
              ...this.searchDates
            }
          })
          .finally(() => {
            this.loading = false;
          });

        const { data, success } = response.data;

        if (success) {
          this.sales = data;
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    FilterBetweenDatesSelect,
    FormatAmount
  },
  mounted() {
    this.getSalesProducts();
  }
};
</script>

<style></style>
