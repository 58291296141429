<template>
  <el-input
    v-model="search"
    placeholder="Buscar ..."
    suffix-icon="el-icon-search"
    class="input-filter-search"
    :clearable="true"
    @input="onChangeInputSearch"
  >
  </el-input>
</template>

<script>
export default {
  name: "InputSearchComponent",
  data: () => ({
    search: null
  }),
  methods: {
    onChangeInputSearch() {
      this.$emit("onChangeSearch", {
        search: this.search
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
