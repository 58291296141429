<template>
  <a
    href="#"
    class="text-color-primary"
    @click="
      () => {
        $router.back();
      }
    "
    ><i class="el-icon-back font-black i-arrow-left-back"></i
  ></a>
</template>

<script>
export default {
  name: "ArrowLeftBack"
};
</script>

<style lang="scss" scoped></style>
