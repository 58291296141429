<template>
  <section class="table-items table-items-quotes" v-loading="loading">
    <b-row class="">
      <b-col xs="12" sm="3" md="3" class="mb-3 mb-lg-0">
        <div class="border-radius md__border mb-2 p-3 bg-color-write h-100">
          <div class="wrapper__number-statistics">
            <div class="wrapper__number-statistics-body">
              <h1 class="h1">
                <FormatAmount :amount="products.total_sales" abbreviate />
              </h1>
              <p class="m-0">
                Ventas <br />
                brutas
              </p>
            </div>
          </div>
        </div>
      </b-col>
      <b-col xs="12" sm="3" md="3" class="mb-3 mb-lg-0">
        <div class="border-radius md__border mb-2 p-3 bg-color-write h-100">
          <div class="wrapper__number-statistics">
            <div class="wrapper__number-statistics-body">
              <h1 class="h1">
                <FormatAmount
                  :amount="products.total_credit_notes"
                  abbreviate
                />
              </h1>
              <p class="m-0">
                Total <br />
                notas de crédito
              </p>
            </div>
          </div>
        </div>
      </b-col>
      <b-col xs="12" sm="3" md="3" class="mb-3 mb-lg-0">
        <div class="border-radius md__border mb-2 p-3 bg-color-write h-100">
          <div class="wrapper__number-statistics">
            <div class="wrapper__number-statistics-body">
              <h1 class="h1">
                <FormatAmount :amount="products.total_net_sales" abbreviate />
              </h1>
              <p class="m-0">
                Ventas <br />
                total
              </p>
            </div>
          </div>
        </div>
      </b-col>
      <b-col xs="12" sm="3" md="3" class="mb-3 mb-lg-0">
        <div class="border-radius md__border mb-2 p-3 bg-color-write h-100">
          <div class="wrapper__number-statistics">
            <div class="wrapper__number-statistics-body">
              <h1 class="h1">{{ products.total_quantity_products }}</h1>
              <p class="m-0">
                Productos <br />
                vendidos
              </p>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col xs="12" sm="3" md="3" class="mb-2 mb-lg-0">
        <InputSearchComponent @onChangeSearch="onChangeSearch" />
      </b-col>
      <b-col xs="12" sm="3" md="3" class="mb-2 mb-lg-0">
        <FilterBetweenDatesSelect
          :defaultMonthValue="true"
          @onAsyncDataForDates="onAsyncDataForDates"
        />
      </b-col>
      <b-col xs="12" sm="3" md="1" class="mb-2 mb-lg-0 pb-2">
        <ButtonPrimaryComponent
          v-if="resultQueryProducts.length"
          title="Excel"
          style="min-height: 40px !important;"
          @onClick="downloadFile"
        />
      </b-col>
    </b-row>

    <section class="input-filters align-items-center mt-4"></section>

    <template v-if="resultQueryProducts.length">
      <b-row class="table-items-header">
        <b-col xs="12" sm="12" md="2">
          <h6 class="h6 ml-2">Código</h6>
        </b-col>
        <b-col xs="12" sm="12" md="3">
          <h6 class="h6">Detalle</h6>
        </b-col>
        <b-col xs="6" sm="12" md="3">
          <h6 class="h6">Categoria</h6>
        </b-col>
        <b-col xs="6" sm="12" md="1">
          <h6 class="h6">Vendido</h6>
        </b-col>
        <b-col xs="4" sm="12" md="1">
          <h6 class="h6">Sub total</h6>
        </b-col>
        <b-col xs="4" sm="12" md="1">
          <h6 class="h6">Nota crédito</h6>
        </b-col>
        <b-col xs="4" sm="12" md="1">
          <h6 class="h6">Venta total</h6>
        </b-col>
      </b-row>
      <paginate
        class="wrapper-pagination"
        name="resultQueryProducts"
        :list="resultQueryProducts"
        :per="perPaginate"
        :key="searchQuery"
      >
        <div
          class="card table-items-body"
          v-for="(item, index) in paginated('resultQueryProducts')"
          :key="index"
        >
          <div class="card-body table-items-content">
            <b-row class="">
              <b-col xs="12" sm="12" md="2" class="table-col-item">
                <span class="item-col-code font-semi-bold">{{
                  item.code
                }}</span>
              </b-col>
              <b-col xs="12" sm="12" md="3" class="table-col-item">
                <span
                  class="item-col-detail font-bold text-primary str-limit-space"
                  >{{ item.name }}</span
                >
              </b-col>
              <b-col xs="6" sm="12" md="3" class="table-col-item">
                <span class="font-semi-bold" v-if="item.category">
                  {{ item.category.title }}</span
                >
              </b-col>
              <b-col xs="6" sm="12" md="1" class="table-col-item">
                <span class="font-semi-bold"> {{ item.quantity }}</span>
              </b-col>
              <b-col xs="4" sm="12" md="1" class="table-col-item">
                <span class="font-semi-bold">
                  <FormatAmount :amount="item.sales" />
                </span>
              </b-col>
              <b-col xs="4" sm="12" md="1" class="table-col-item">
                <span class="font-semi-bold">
                  <FormatAmount :amount="item.credit_note_sales" />
                </span>
              </b-col>
              <b-col xs="4" sm="12" md="1" class="table-col-item">
                <span class="font-semi-bold">
                  <FormatAmount :amount="item.net_sales" />
                </span>
              </b-col>
            </b-row>
          </div>
        </div>
      </paginate>

      <paginate-links
        v-show="resultQueryProducts.length > perPaginate"
        for="resultQueryProducts"
        :classes="{ ul: 'pagination', li: 'page-item', a: 'page-link' }"
        :simple="{
          next: 'Siguiente »',
          prev: '« Regresar'
        }"
      ></paginate-links>
    </template>
  </section>
</template>

<script>
import reportService from "../services/reportService";
import InputSearchComponent from "../../components/InputSearch";
import FilterBetweenDatesSelect from "../../components/FilterBetweenDatesSelect";
import FormatAmount from "@/components/General/FormatAmount";
import ButtonPrimaryComponent from "../../../../components/Buttons/ButtonPrimary";
//src\components\Buttons\ButtonPrimary.vue

export default {
  name: "SalesProductList",
  data() {
    return {
      paginate: ["resultQueryProducts"],
      perPaginate: 12,
      products: [],
      searchQuery: null,
      loading: false
    };
  },
  computed: {
    resultQueryProducts() {
      const { products } = this.products;
      return (
        (products &&
          products
            .concat()
            .filter(item =>
              this.searchQuery
                ? item.name
                    .toLowerCase()
                    .includes(this.searchQuery.toLowerCase())
                : item
            )) ||
        []
      );
    }
  },
  methods: {
    onChangeSearch({ search }) {
      this.searchQuery = search;
    },
    onAsyncDataForDates({ searchDates }) {
      this.searchDates = searchDates;
      this.products = [];
      this.getSalesProducts();
    },
    async downloadFile() {
      this.loading = true;

      try {
        const response = await reportService
          .getDownloadReportExcel({
            params: {
              ...{},
              ...this.searchDates
            },
            headers: {
              responseType: "blob",
              ContentType: "application/vnd.ms-excel"
            }
          })
          .finally(() => {
            this.loading = false;
          });

        const { data, success } = response.data;
        if (success) {
          const date = new Date()
            .toISOString()
            .slice(0, 19)
            .replace(/-/g, "_")
            .replace("T", "_");
          const anchor = document.createElement("a");
          const filename = `${date}_reporte-ventas.xlsx`;
          anchor.setAttribute("download", filename);
          anchor.setAttribute("href", data);
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        }
      } catch (error) {
        return false;
      }

      /* const response = await reportService
        .getDownloadReportExcel({
          params: {
            ...{},
            ...{ excel: true },
            ...this.searchDates
          },
          headers: {
            responseType: 'blob',
            ContentType: 'application/vnd.ms-excel'
          }
        })
        .then(res => {
          const anchor = document.createElement("a");
          const filename = this.date+"payroll.xlsx";
          anchor.setAttribute("download", filename);
          anchor.setAttribute("href", res.data.data);
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        }); */
    },
    async getSalesProducts() {
      try {
        this.loading = true;

        const response = await reportService
          .getSalesProducts({
            params: {
              ...{},
              ...this.searchDates
            }
          })
          .finally(() => {
            this.loading = false;
          });

        const { data, success } = response.data;

        if (success) {
          this.products = data;
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    InputSearchComponent,
    FilterBetweenDatesSelect,
    FormatAmount,
    ButtonPrimaryComponent
  },
  mounted() {
    this.getSalesProducts();
  }
};
</script>

<style scoped>
.btn-sm {
  font-size: 12px;
  line-height: 1.5;
  padding: 5px 10px;
}
</style>
