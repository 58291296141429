<template>
  <section class="">
    <TopBarTitleComponent title="Reportes" :showBackIcon="true">
      <template slot="right-area"> </template>
    </TopBarTitleComponent>
    <b-container fluid class="pb-4 px-lg-4">
      <el-tabs v-model="activeTabName">
        <el-tab-pane label="Facturas vs Cotizaciones" name="comparative">
          <b-row>
            <b-col>
              <QuotesToInvoicesComponent isSearchYear />
            </b-col>
          </b-row>
        </el-tab-pane>
        <el-tab-pane label="Ventas productos" name="products">
          <b-row>
            <b-col>
              <SalesProductList />
            </b-col>
          </b-row>
        </el-tab-pane>
        <el-tab-pane label="IVA" name="iva">
          <b-row>
            <b-col>
              <SalesIvaList />
            </b-col>
          </b-row>
        </el-tab-pane>
        <el-tab-pane label="Facturas" name="invoices">
          <b-row>
            <b-col>
              <InvoicesList />
            </b-col>
          </b-row>
        </el-tab-pane>
      </el-tabs>
    </b-container>
  </section>
</template>

<script>
import TopBarTitleComponent from "../../components/TopBarTitle";
import QuotesToInvoicesComponent from "../components/QuotesToInvoices";
import SalesProductList from "../components/SalesProductList";
import SalesIvaList from "../components/SalesIvaList";
import InvoicesList from "../components/InvoicesList.vue";

export default {
  data() {
    return {
      activeTabName: "comparative"
    };
  },
  components: {
    TopBarTitleComponent,
    QuotesToInvoicesComponent,
    SalesProductList,
    SalesIvaList,
    InvoicesList
  }
};
</script>

<style></style>
