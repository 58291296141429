<template>
  <section class="table-items table-items-quotes" v-loading="loading">
    <b-row class="mt-4">
      <b-col xs="12" sm="3" md="3" class="mb-2 mb-lg-0">
        <InputSearchComponent @onChangeSearch="onChangeSearch" />
      </b-col>
      <b-col xs="12" sm="3" md="3" class="mb-2 mb-lg-0">
        <FilterBetweenDatesSelect
          :defaultMonthValue="true"
          @onAsyncDataForDates="onAsyncDataForDates"
        />
      </b-col>
      <b-col xs="12" sm="12" md="5" class="mb-2 mb-lg-0 pb-2">
        <ButtonPrimaryComponent
          v-if="resultQueryInvoices.length"
          title="Excel"
          style="min-height: 40px !important;"
          @onClick="downloadFile"
        />
        <ButtonInfoComponent
          v-if="resultQueryInvoices.length"
          title="PDF"
          style="min-height: 40px !important; margin-left: 10px;"
          @onClick="sendReport"
        />
        <span id="url_download" class="ml-3"></span>
      </b-col>
    </b-row>

    <section class="input-filters align-items-center mt-4"></section>

    <template v-if="resultQueryInvoices.length">
      <b-row class="table-items-header">
        <b-col xs="12" sm="12" md="2">
          <h6 class="h6 ml-2">Código</h6>
        </b-col>
        <b-col xs="12" sm="12" md="3">
          <h6 class="h6">Cliente</h6>
        </b-col>
        <b-col xs="6" sm="12" md="3">
          <h6 class="h6">Fecha</h6>
        </b-col>
        <b-col xs="6" sm="12" md="2">
          <h6 class="h6">Catidad</h6>
        </b-col>
        <b-col xs="4" sm="12" md="2">
          <h6 class="h6">Monto</h6>
        </b-col>
      </b-row>
      <paginate
        class="wrapper-pagination"
        name="resultQueryInvoices"
        :list="resultQueryInvoices"
        :per="perPaginate"
        :key="searchQuery"
      >
        <div
          class="card table-items-body"
          v-for="(invoice, index) in paginated('resultQueryInvoices')"
          :key="index"
        >
          <div class="card-body table-items-content">
            <b-row class="">
              <b-col xs="12" sm="12" md="2" class="table-col-item">
                <span class="item-col-code font-semi-bold">
                  {{ invoice.number_invoice }}
                </span>
              </b-col>
              <b-col xs="12" sm="12" md="3" class="table-col-item">
                <span
                  class="item-col-detail font-bold text-primary str-limit-space"
                  >{{ invoice.client.name }}</span
                >
              </b-col>
              <b-col xs="12" sm="12" md="3" class="table-col-item">
                <span
                  class="item-col-detail font-bold text-primary str-limit-space"
                  >{{ invoice.emission_date }}</span
                >
              </b-col>
              <b-col xs="12" sm="12" md="2" class="table-col-item">
                <span
                  class="item-col-detail font-bold text-primary str-limit-space"
                  >{{ invoice.products.length }}</span
                >
              </b-col>
              <b-col xs="12" sm="12" md="2" class="table-col-item">
                <span
                  class="item-col-detail font-bold text-primary str-limit-space"
                  ><FormatAmount :amount="invoice.total"
                /></span>
              </b-col>
            </b-row>
          </div>
        </div>
      </paginate>

      <paginate-links
        v-show="resultQueryInvoices.length > perPaginate"
        for="resultQueryInvoices"
        :classes="{ ul: 'pagination', li: 'page-item', a: 'page-link' }"
        :simple="{
          next: 'Siguiente »',
          prev: '« Regresar'
        }"
      ></paginate-links>
    </template>
  </section>
</template>

<script>
import reportService from "../services/reportService";
import InputSearchComponent from "../../components/InputSearch";
import FilterBetweenDatesSelect from "../../components/FilterBetweenDatesSelect";
import FormatAmount from "@/components/General/FormatAmount";
import ButtonPrimaryComponent from "../../../../components/Buttons/ButtonPrimary";
import ButtonInfoComponent from "../../../../components/Buttons/ButtonInfo";

export default {
  name: "InvoicesList",
  data() {
    return {
      paginate: ["resultQueryInvoices"],
      perPaginate: 12,
      invoices: [],
      searchQuery: null,
      loading: false,
      data: []
    };
  },
  computed: {
    resultQueryInvoices() {
      const { invoices } = this.invoices;
      //const invoices = this.invoices;
      return (
        (invoices &&
          invoices
            .concat()
            .filter(item =>
              this.searchQuery
                ? item.number_invoice
                    .toLowerCase()
                    .includes(this.searchQuery.toLowerCase())
                : item
            )) ||
        []
      );
    }
  },
  methods: {
    onChangeSearch({ search }) {
      this.searchQuery = search;
    },
    onAsyncDataForDates({ searchDates }) {
      this.searchDates = searchDates;
      this.invoices = [];
      this.getInvoicesList();
    },
    dateNow() {
      return new Date()
        .toISOString()
        .slice(0, 19)
        .replace(/-/g, "_")
        .replace("T", "_");
    },
    async downloadFile() {
      this.loading = true;

      try {
        const response = await reportService
          .getDownloadReportInvoicesExcel({
            params: {
              ...{},
              ...this.searchDates
            },
            headers: {
              responseType: "blob",
              ContentType: "application/vnd.ms-excel"
            }
          })
          .finally(() => {
            this.loading = false;
          });

        const { data, success } = response.data;
        if (success) {
          const date = this.dateNow();
          const anchor = document.createElement("a");
          const filename = `${date}_reporte_facturas.xlsx`;
          anchor.setAttribute("download", filename);
          anchor.setAttribute("href", data);
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        }
      } catch (error) {
        return false;
      }
    },
    async sendReport() {
      this.loading = true;

      try {
        const response = await reportService
          .getSendReportInvoicesEmail({
            params: {
              ...{},
              ...this.searchDates
            }
          })
          .finally(() => {
            this.loading = false;
          });

        const { success, data } = response.data;
        if (success) {
          const date = this.dateNow();
          const containerUrl = document.getElementById("url_download");
          const anchor = document.createElement("a");
          const filename = `${date}_descarga_reporte_facturas.pdf`;
          anchor.setAttribute("download", filename);
          anchor.setAttribute("href", data);
          anchor.innerHTML = filename;
          if (containerUrl.firstChild) {
            containerUrl.removeChild(containerUrl.firstChild);
          }
          containerUrl.appendChild(anchor);
        }
      } catch (error) {
        return false;
      }
    },

    async getInvoicesList() {
      try {
        this.loading = true;

        const response = await reportService
          .getDataInvoices({
            params: {
              ...{},
              ...this.searchDates
            }
          })
          .finally(() => {
            this.loading = false;
          });

        const { data, success } = response.data;

        if (success) {
          this.invoices = data;
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    InputSearchComponent,
    FilterBetweenDatesSelect,
    FormatAmount,
    ButtonPrimaryComponent,
    ButtonInfoComponent
  },
  mounted() {
    //this.getInvoicesList();
  }
};
</script>

<style scoped>
.btn-sm {
  font-size: 12px;
  line-height: 1.5;
  padding: 5px 10px;
}
</style>
